<template>
  <div class="M3">
    <div class="M3_top">
      <van-image width="100%" fit="cover" :src="topImg" />
    </div>
    <div class="M3_main">
      <van-form @submit="onSubmit">
        <van-field
          v-model="form.userName"
          required
          name="userName"
          label="姓名"
          placeholder="姓名"
          :rules="[{ required: true }]"
        />
        <!-- <van-field
          name="userSex"
          label="您的性别"
          required
          :rules="[{ required: true }]"
        >
          <template #input>
            <van-radio-group
              v-model="form.userSex"
              direction="horizontal"
              icon-size="15px"
            >
              <van-radio name="0">先生</van-radio>
              <van-radio name="1">女士</van-radio>
            </van-radio-group>
          </template>
        </van-field> -->
        <van-field
          v-model="form.userCompany"
          :rules="[{ required: true }]"
          required
          name="userCompany"
          label="公司"
          placeholder="公司"
        />
        <!-- <van-field
          v-model="form.answerPosition"
          name="answerPosition"
          label="您的职位"
          placeholder="您的职位"
        /> -->
        <!-- <van-field
          v-model="form.userCompanyScale"
          name="userCompanyScale"
          type="digit"
          label="您公司的规模"
          placeholder="您公司的规模"
        /> -->

        <div style="margin: 16px">
          <van-button block type="info" native-type="submit">下一步</van-button>
        </div>
      </van-form>
    </div>
    <copyrightIp class="copyrightIp" />
  </div>
</template>
<script>
import {
  Image as VanImage,
  Form,
  Field,
  Button,
  RadioGroup,
  Radio,
} from "vant";
import { getTSpecificById, addmodel3 } from "@/api/model";
import getJSSDK from "@/utils/wxShare";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    VanImage,
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    copyrightIp,
  },
  data() {
    return {
      topImg: "",

      form: {
        userName: "", //名字
        userSex: "", //性别
        userCompany: "", //公司
        answerPosition: "", //职位
        userCompanyScale: "", //规模
        fromType: "", // (0:联系我们  1粉丝提问)
        openId: "", //
      },
    };
  },
  methods: {
    getTSpecific() {
      let tspecificId = window.location.hash.split("?")[1].split("=")[1];
      getTSpecificById({ tspecificId }).then((res) => {
        this.topImg = res.data.data.tspecificTempItems[0]?.itemValue;
        getJSSDK({
          title: res.data.data.tspecific.specificName || "中智薪税",
          link: this.$route.fullPath,
        });
      });
    },
    onSubmit(values) {
      if (localStorage.getItem("status") == 102) {
        this.form.openId =
          localStorage.getItem("code") || "oE1PV5krMUmcVe6GLqHcKX8s-Tj8";
        addmodel3(this.form).then((res) => {
          this.$router.push("/contactUs/success");
        });
      } else {
        localStorage.setItem("historyRoute", this.$route.fullPath);
        this.$router("/login");
      }
    },
  },
  mounted() {
    this.getTSpecific();
  },
};
</script>
<style lang="scss" scoped>
.M3 {
  position: relative;
  padding-bottom: 50px;
  min-height: 100%;
  .copyrightIp {
    width: 100%;
    position: absolute;
    bottom: 5px;
    left: 0;
  }
}
.M3_top {
  width: 100%;
  .van-image {
    min-height: 350px;
  }
}
.M3_main {
  width: 100%;
  background: #fff;
  padding-bottom: 1px;
}
</style>
